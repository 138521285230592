<template>
  <div class="text-center">
    <v-dialog
      v-model="isModalOpen"
      persistent
      width="800px"
      max-width="900px"
      max-height="400px"
    >
      <v-card>
        <v-card-title>
          <slot name="header">
            <span>
              {{ submitButtonName }} dataset for selected transaction(s)
            </span>
          </slot>
        </v-card-title>
        <v-card-text>
          <slot name="body"></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text-color="white"
            @click="isModalOpen = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text-color="white"
            @click="assign"
            :disabled="disabled"
          >
            {{ submitButtonName }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { mapGetters } from 'vuex'

export default {
  name: 'DatasetAssignmentCreationModal',

  data() {
    return {}
  },
  computed: {
    ...mapGetters('datasets', ['getDatasets']),
    isModalOpen: {
      get: function () {
        return this.active
      },
      set: function (val) {
        this.$emit('update:active', val)
      },
    },
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    assign: {
      type: Function,
      required: true,
    },
    submitButtonName: {
      type: String,
      required: false,
      default: () => 'Assign',
    },
  },
}
</script>
  
  <style>
</style>