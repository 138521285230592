<template>
  <div>
    <div
      v-if="isRetrievingTransactions"
      v-bind:class="[isRetrievingTransactions ? 'is-loading' : '']"
    >
      <h1>Loading...</h1>
    </div>
    <div class="container pb-180p">
      <v-data-table
        :headers="headers"
        :items="transactions"
        :items-per-page="getTransactionsPerPage"
        :page="getPage"
        v-model="selected_transactions"
        hide-default-footer
        show-select
        class="elevation-1"
        multi-sort
      >

        <template v-slot:top>
          <v-row
            no-gutters
            style="cursor: default;"
          >
            <v-col class="d-flex justify-start align-center ml-3">
              <v-text-field
                v-model="transactionDescription"
                @change="handleSearchByDescription"
                label="Search by description"
                prepend-icon="mdi-magnify"
                hint="Press enter to search"
                clearable
              ></v-text-field>
            </v-col>
            <v-col class="d-flex justify-end mr-3">
              <div>
                <v-menu
                  ref="filterMenu"
                  v-model="isFilterMenuOpen"
                  :close-on-content-click="false"
                  offset-y
                  :nudge-left="150"
                  :max-width="300"
                  rounded
                >

                  <template v-slot:activator="{ on: isFilterMenuOpen, attrs }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: filterTooltip }">
                        <v-btn
                          icon
                          color="rgba(0, 0, 0, 0.6)"
                          dark
                          class="mt-3 mb-3 mr-3"
                          v-bind="attrs"
                          v-on="{...isFilterMenuOpen, ...filterTooltip}"
                        >
                          <v-icon>mdi-filter-settings</v-icon>
                        </v-btn>
                      </template>
                      <span>Filters</span>
                    </v-tooltip>
                  </template>

                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-select
                          v-model="selectedWithLabel"
                          :items="filterableValues"
                          label="With labels"
                          hide-details
                          return-object
                          clearable
                        />
                      </v-list-item>

                      <v-list-item>
                        <v-select
                          v-model="selectedAssignedPendingTasks"
                          :items="filterableValues"
                          label="With assigned pending tasks"
                          hide-details
                          return-object
                          clearable
                        />
                      </v-list-item>

                      <v-list-item>
                        <v-select
                          v-model="selectedDataset"
                          :items="getDatasets"
                          item-text="name"
                          label="Filter by Dataset"
                          hide-details
                          @change="handleDatasetChange"
                          return-object
                          clearable
                        />
                      </v-list-item>
                      <v-list-item>
                        <v-select
                          :value="selectedSchemasToFilter"
                          :items="getLabelSchemasWithAll"
                          label="Filter by Label Schema"
                          hide-details
                          multiple
                          chips
                          @change="handleSchemaChange"
                        />
                      </v-list-item>
                    </v-list>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        text
                        @click="isFilterMenuOpen = false;"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="onUpdateFilters(); isFilterMenuOpen = false;"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
              <div>
                <v-menu
                  ref="sortMenu"
                  v-model="isSortMenuOpen"
                  :close-on-content-click="false"
                  offset-y
                  :nudge-left="100"
                  :max-width="300"
                  rounded
                >
                  <template v-slot:activator="{ on: isSortMenuOpen, attrs }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: sortTooltip }">
                        <v-btn
                          icon
                          color="rgba(0, 0, 0, 0.6)"
                          dark
                          class="mt-3 mb-3 mr-3"
                          v-bind="attrs"
                          v-on="{...isSortMenuOpen, ...sortTooltip}"
                        >
                          <v-icon>mdi-sort</v-icon>
                        </v-btn>
                      </template>
                      <span>Sort </span>
                    </v-tooltip>
                  </template>

                  <v-card>
                    <v-list>
                      <v-list-item>
                        <v-select
                          v-model="selectedSortingOrder"
                          :items="sortingOrders"
                          label="Sorting order"
                          hide-details
                          clearable
                        />
                      </v-list-item>

                      <v-list-item>
                        <v-select
                          v-model="selectedOrderBy"
                          :items="orderByValues"
                          label="Order by"
                          hide-details
                          clearable
                        />
                      </v-list-item>

                    </v-list>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        text
                        @click="isSortMenuOpen = false;"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="onUpdateSortFilters(); isSortMenuOpen = false;"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </div>
              <div>
                <v-btn
                  v-if="selected_transactions.length === 0"
                  class="mt-3 mb-3"
                  color="secondary"
                  outlined
                  @click="selectAllTransactions"
                >
                  Select all
                </v-btn>
                <v-btn
                  v-if="selected_transactions.length > 0"
                  class="mt-3 mb-3"
                  color="secondary"
                  outlined
                  @click="deselectAllTransactions"
                >

                  Deselect all
                </v-btn>
                <h1
                  class="text-center d-block"
                  style="font-size: 12px;"
                >
                  {{ selected_transactions.length }} transactions selected
                </h1>
              </div>
              <template>
                <v-btn
                  class="mt-3 mb-3 ml-3"
                  color="primary"
                  outlined
                  :disabled="selected_transactions.length === 0"
                  @click="isCreationDialogOpen = true"
                >
                  Create Task
                </v-btn>
              </template>
              <labeler-assignment-modal
                :active.sync="isCreationDialogOpen"
                :selectedItems="selected_transactions"
                :submitButtonName="'Create'"
                @close="isCreationDialogOpen = false"
                :assign="create_task"
                :disabled="!selectedLabelerToAssign || !selectedSchemaToAssign"
              >
                <template v-slot:body>
                  <v-select
                    v-model="selectedSchemaToAssign"
                    :items="getLabelSchemas"
                    label="Schema"
                    hide-details
                    clearable
                  />
                  <v-select
                    v-model="selectedLabelerToAssign"
                    :items="getLabelersWithNull"
                    item-text="email_address"
                    item-value="id"
                    label="Labeler"
                    hide-details
                    clearable
                    :disabled="!selectedSchemaToAssign"
                  >
                    <template #item="{item}">
                      <div :class="item.email_address === 'Anyone' ? 'divider-top' : null">{{ item.email_address }}</div>
                    </template>
                  </v-select>
                </template>
              </labeler-assignment-modal>
              <v-tooltip
                top
                v-if="selected_transactions.length === 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-btn
                      class="ma-3"
                      color="primary"
                      :disabled="selected_transactions.length === 0"
                    >
                      Dataset Actions
                    </v-btn>
                  </span>
                </template>
                <span>Select transactions first</span>
              </v-tooltip>
              <v-btn
                v-if="selected_transactions.length > 0"
                class="ma-3"
                color="primary"
                @click="isDatasetModalOpen = true"
              >
                Dataset Actions
              </v-btn>

              <dataset-assignment-creation-modal
                :active.sync="isDatasetModalOpen"
                :submitButtonName="`${selectedRadio === 1 ? 'Add & assign' : selectedRadio === 2? 'Create & assign' : 'Remove'}`"
                @close="isDatasetModalOpen = false"
                :assign="selectedRadio === 1 ? assignTransactionsToDataset : selectedRadio === 2 ? createAndAssignTransactionsToDataset : handleRemoveTransactionsFromDataset"
                :disabled="selectedRadio === 1 ? !selectedDatasetToAssign  : selectedRadio === 2 ? !newDatasetName : !selectedDatasetToRemove"
              >
                <template v-slot:body>
                  <v-radio-group v-model="selectedRadio">
                    <v-radio
                      v-for="n in 3"
                      :key="n"
                      :label="`${n === 1 ? 'Add to existing dataset' : n === 2 ? 'Create new dataset' : 'Remove from existing dataset'}`"
                      :value="n"
                    ></v-radio>
                  </v-radio-group>
                  <v-select
                    v-if="selectedRadio === 1"
                    v-model="selectedDatasetToAssign"
                    @change="onDatasetChange"
                    :items="getDatasets"
                    :item-text="getDatasetFieldText"
                    label="Existing dataset"
                    hide-details
                    clearable
                  />
                  <v-text-field
                    v-if="selectedRadio === 2"
                    v-model="newDatasetName"
                    label="Create new dataset"
                    hide-details
                    clearable
                  />
                  <span v-if="selectedRadio === 3">
                    <ul><span class="red--text">Warning:</span>
                      <li>
                        This will remove the selected transactions from the selected dataset.
                      </li>
                      <li>
                        If you remove all transactions that are associated to the dataset, <span class="red--text">the dataset will also be removed</span>.
                      </li>
                    </ul>
                  </span>
                  <v-select
                    v-if="selectedRadio === 3"
                    v-model="selectedDatasetToRemove"
                    @change="e => selectedDatasetToRemove = e"
                    :items="getDatasets"
                    :item-text="getDatasetFieldText"
                    label="Remove from existing dataset"
                    hide-details
                    clearable
                  />
                </template>
              </dataset-assignment-creation-modal>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
    <div class="footer">
      <v-pagination
        :value="getPage"
        :length="getNbPages"
        total-visible="12"
        @input="updatePage"
      ></v-pagination>
      <div class="input">
        <v-text-field
          :value="getTransactionsPerPage"
          label="Transactions per page"
          type="number"
          min="1"
          max="100"
          @change="updateNbTransactionsPerPage"
          hint="Press enter to update"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DatasetAssignmentCreationModal from '../components/DatasetAssignmentCreationModal.vue'
import LabelerAssignmentModal from '../components/LabelerAssignmentModal.vue'

export default {
  components: {
    LabelerAssignmentModal,
    DatasetAssignmentCreationModal,
  },
  name: 'Transactions',
  computed: {
    ...mapGetters('transactions', [
      'getTransactions',
      'getDatasets',
      'getSelectedDataset',
      'getWithLabels',
      'getWithAssignedPendingTasks',
      'getPage',
      'getNbPages',
      'getTransactionsPerPage',
    ]),
    ...mapGetters('labelers', [
      'getLabelers',
      'getLabelers',
      'getLabelersPage',
      'getLabelersNbPages',
    ]),
    ...mapGetters('labels', ['getLabelSchemas']),
    getLabelersWithNull() {
      return this.getLabelers.concat([{ email_address: 'Anyone', id: 'any' }])
    },
    getLabelSchemasWithAll: function () {
      let label_schema = Array.from(this.getLabelSchemas)
      label_schema.push('all')
      return label_schema
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Transaction',
          align: 'start',
          value: 'description',
          sortable: false,
        },
        { text: 'Account Type', value: 'account_holder_type', sortable: false },
        { text: 'Entry Type', value: 'entry_type', sortable: false },
        { text: 'Amount', value: 'amount' },
        { text: 'Date', value: 'date' },
      ],
      selected_transactions: [],
      transactions: [],
      selectedSchemaToAssign: '',
      selectedLabelerToAssign: null,
      selectedSchemasToFilter: null,
      isCreationDialogOpen: false,
      transactionDescription: '',
      datasetFilter: '',
      isFilterMenuOpen: false,
      isSortMenuOpen: false,
      selectedDataset: null,
      filterableValues: ['include', 'exclude', 'any'],
      sortingOrders: ['asc', 'desc'],
      selectedSortingOrder: null,
      orderByValues: ['created_at'],
      selectedOrderBy: null,
      selectedWithLabel: null,
      selectedAssignedPendingTasks: null,
      isDatasetModalOpen: false,
      selectedDatasetToAssign: '',
      isFromExistingDataset: true,
      selectedRadio: 1,
      newDatasetName: '',
      selectedDatasetToRemove: '',
      isRetrievingTransactions: false,
    }
  },
  methods: {
    ...mapActions('transactions', [
      'retrieveTransactions',
      'retrieveDatasets',
      'retrieveLabelSchemas',
    ]),
    ...mapMutations('transactions', [
      'setTransactions',
      'setTransactionDescription',
      'setSelectedDataset',
      'setWithLabels',
      'setWithAssignedPendingTasks',
      'setSortingOrder',
      'setOrderBy',
      'setSelectedSchemas',
      'setPage',
      'setTransactionsPerPage',
    ]),
    ...mapActions('task', ['createTasks']),
    ...mapActions('datasets', [
      'addTransactionsWithDataset',
      'removeTransactionsFromDataset',
    ]),
    async selectAllTransactions() {
      await this.retrieveTransactions()
      this.selected_transactions = this.getTransactions.items
    },
    async deselectAllTransactions() {
      this.selected_transactions = []
    },
    async handleSearchByDescription(e) {
      if (
        !this.transactionDescription ||
        this.transactionDescription.trim() === ''
      ) {
        this.setTransactionDescription('')
      } else {
        this.setTransactionDescription(e)
      }
      await this.fetchTransactions()
    },
    async create_task() {
      this.isCreationDialogOpen = false
      this.createTasks({
        transaction_ids: this.selected_transactions.map((x) => x.id),
        schema_version: this.selectedSchemaToAssign,
        assignee_id:
          this.selectedLabelerToAssign !== 'any'
            ? this.selectedLabelerToAssign
            : null,
      })
      this.selected_transactions = []
    },
    async updatePage(selectedPageNumber) {
      if (this.getPage === selectedPageNumber) return
      this.setPage(selectedPageNumber)
      await this.fetchTransactions()
    },
    async updateNbTransactionsPerPage(inputNumber) {
      inputNumber = parseInt(inputNumber)
      if (inputNumber !== '') {
        if (inputNumber < 1) {
          inputNumber = 1
        }
        if (inputNumber > 100) {
          inputNumber = 100
        }
        this.setTransactionsPerPage(inputNumber)
        await this.fetchTransactions()
      }
    },
    handleDatasetChange(e) {
      if (!e) {
        this.selectedDataset = null
      } else {
        this.selectedDataset = e.name
      }
    },
    handleSchemaChange(e) {
      console.log(e)
      if (!e) {
        this.selectedSchemasToFilter = null
      } else {
        this.selectedSchemasToFilter = e
      }
    },
    async onUpdateFilters() {
      this.setSelectedDataset(this.selectedDataset)
      this.setWithLabels(this.selectedWithLabel)
      this.setWithAssignedPendingTasks(this.selectedAssignedPendingTasks)
      this.setSelectedSchemas(this.selectedSchemasToFilter)
      await this.fetchTransactions()
    },
    async onUpdateSortFilters() {
      this.setSortingOrder(this.selectedSortingOrder)
      this.setOrderBy(this.selectedOrderBy)
      await this.fetchTransactions()
    },
    async fetchTransactions() {
      await this.retrieveTransactions()
      this.transactions = this.getTransactions.items
    },
    onDatasetChange(e) {
      this.selectedDatasetToAssign = e
    },
    getDatasetFieldText(item) {
      return `${item.name} - (${item.size} txs) - ${item.updated_at}`
    },
    async assignTransactionsToDataset() {
      this.isDatasetModalOpen = false
      await this.addTransactionsWithDataset({
        datasetName: this.selectedDatasetToAssign,
        transactions: this.selected_transactions,
      })
      // reset
      this.selectedDatasetToAssign = ''
      this.selected_transactions = []
    },
    async createAndAssignTransactionsToDataset() {
      this.isDatasetModalOpen = false
      await this.addTransactionsWithDataset({
        datasetName: this.newDatasetName,
        transactions: this.selected_transactions,
      })
      // reset
      this.newDatasetName = ''
      this.selected_transactions = []
      this.retrieveDatasets()
    },
    async handleRemoveTransactionsFromDataset() {
      this.isDatasetModalOpen = false
      await this.removeTransactionsFromDataset({
        datasetName: this.selectedDatasetToRemove.split(' ')[0],
        transactions: this.selected_transactions,
      })
      // reset
      this.selectedDatasetToRemove = ''
      this.selected_transactions = []
      await this.retrieveDatasets()
    },
    reset() {
      this.setSelectedDataset(null)
      this.setWithLabels(null)
      this.setWithAssignedPendingTasks(null)
      this.setSortingOrder(null)
      this.setOrderBy(null)
      this.setSelectedSchemas(null)
      this.setTransactionDescription(null)
      this.setPage(1)
      this.setTransactionsPerPage(100)
    },
    async onLoad() {
      this.isRetrievingTransactions = true
      this.reset()
      await this.fetchTransactions()
      await this.retrieveDatasets()
      await this.retrieveLabelSchemas()
      this.isRetrievingTransactions = false
    },
  },
  async created() {
    await this.onLoad()
    if (this.$route.params && this.$route.params.dataset) {
      this.selectedDataset = this.$route.params.dataset.name
      this.onUpdateFilters()
    }
  },
}
</script>
  
  <style></style>